/**
 * import
 *
 * @version 0.1 (2020.11.04 : usayama)
 * @version 0.2 (2021.05.18 : usayama) // js-cookie 追加
 */
// import React from 'react'
import Cookies from 'js-cookie'
// conf, action
// consts

/**
 * GET : header
 * 
 * @version 1.0 (2020.11.04 : usayama)
 */
export function clearToken() {
  localStorage.removeItem('accessToken');
}

/**
 * GET : header
 * 
 * @version 1.0 (2020.11.04 : usayama)
 */
export function getToken() {
  return localStorage.getItem('accessToken');
}

/**
 * SET
 * 
 * @version 1.0 (2020.11.04 : usayama)
 */
export function setToken(accessToken) {
  localStorage.setItem('accessToken', accessToken);
}

/**
 * axios 
 * 
 * @version 1.0 (2021.05.25 : usayama)
 */
export function getBearer() {
  const accessToken = localStorage.getItem('accessToken')
  return {
    headers: {
        Authorization: 'Bearer ' + accessToken,
    }
  };
}

/**
 * axios 
 * 
 * @version 1.0 (2021.06.08 : usayama)
 */
export function getBearerMultipart() {
  const accessToken = localStorage.getItem('accessToken')
  return {
    headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'multipart/form-data',
        // 'Access-Control-Allow-Origin': '*',
        // 'boundary': Math.random().toString().substr(2)
    }
  };
}

/**
 * GET : header
 * 
 * @version 1.0 (2020.04.02 : usayama) // mypage から流用
 */
export function getRequestHeader() {
  return {
    method: 'GET',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
  };
}

/**
 * POST : header
 * 
 * @version 1.0 (2020.04.02 : usayama) // mypage から流用
 */
export function postRequestHeader(body_obj = {}) {
  return {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(body_obj)
  };
}

/**
 * GET : header
 * 
 * @version 1.0 (2020.04.03)
 */
export function ajaxGetRequestHeader() {
  return {
    method: 'GET',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
  };
}

/**
 * POST : header
 * 
 * @version 1.0 (2020.04.02 : usayama)
 * @version 1.1 (2021.05.17 : usayama) // X-XSRF-TOKEN 追加
 */
export function ajaxPostRequestHeader(body_obj = {}) {
  return {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getMeta('csrf-token'),
    },
    body: JSON.stringify(body_obj)
  };
}

/**
 * POST : header
 * 
 * @version 1.0 (2021.05.17 : usayama) // X-XSRF-TOKEN 追加
 */
export function ajaxCrosPostRequestHeader(body_obj = {}) {
  return {
    method: 'POST',
    mode: 'cors',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
        // 'X-CSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
    },
    body: JSON.stringify(body_obj)
  };
}

/**
 * POST : header
 * 
 * @version 1.0 (2020.04.07)
 * @version 1.2 (2020.07.09) // App から流用
 */
export function ajaxPostDataFileRequestHeader(body_obj = {}) {
  // init
  const formData  = new FormData();
  for(const name in body_obj) {
    Object.keys(body_obj[name]).map((key, index) => {
      formData.append(key, (body_obj[name][key]) ? body_obj[name][key] : '');
    });
  }
  return {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'X-CSRF-TOKEN': getMeta('csrf-token'),
    },
    body: formData
  };
}

/**
 * accessToken を付与した header 作成 : POST
 * 
 * @version 1.0 (2020.01.09)
 * @version 1.1 (2020.01.10) // メソッド名変更
 * @version 1.2 (2020.11.04 : usayama) // localStorage から直接取得
 */
export function apiPostRequestHeader(body_obj = {}) {
  // const accessToken = getToken().get('accessToken');
  const accessToken = localStorage.getItem('accessToken');
  return {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(body_obj)
  };
}

/**
 * POST : header
 * 
 * @version 1.0 (2020.07.09)
 * @version 1.1 (2020.07.15) // 配列かどうかのチェック追加
 */
export function apiPostDataFileRequestHeader(body_obj = {}) {
  // init
  const accessToken = localStorage.getItem('accessToken');
  const formData  = new FormData();
  for(const name in body_obj) {
    Object.keys(body_obj[name]).map((key, index) => {
      if(Array.isArray(body_obj[name][key])) {
        body_obj[name][key].map((k, i) => {
          formData.append(key + '[]', (k) ? k : '');
        });
      } else {
        formData.append(key, (body_obj[name][key]) ? body_obj[name][key] : '');
      }
    });
  }
  return {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
    // body: JSON.stringify(formData)
    body: formData
  };
}

/**
 * accessToken を付与した header 作成 : POST
 * 
 * @version 1.0 (2020.01.30)
 * @version 1.1 (2020.11.04 : usayama) // localStorage から直接取得
 */
export function apiPostDataRequestHeader(data) {
  // const accessToken = getToken().get('accessToken');
  const accessToken = localStorage.getItem('accessToken');
  return {
    method: 'POST',
    headers: {
        Authorization: 'Bearer ' + accessToken,
    },
    body: data
  };
}

/**
 * accessToken を付与した header 作成 : GET
 * 
 * @version 1.0 (2020.01.10)
 * @version 1.1 (2020.11.04 : usayama) // localStorage から直接取得
 */
export function apiGetRequestHeader() {
  // const accessToken = getToken().get('accessToken');
  const accessToken = localStorage.getItem('accessToken');
  return {
    method: 'GET',
    headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
    }
  };
}

/**
 * accessToken を付与した header 作成 : GET
 * 
 * @version 0.1 (2020.12.07)
 */
export function apiGetPdfRequestHeader() {
  // const accessToken = getToken().get('accessToken');
  const accessToken = localStorage.getItem('accessToken');
  return {
    method: 'GET',
    // dataType: 'text',
    // responseType: 'json',
    'responseType': 'arraybuffer',
    headers: {
        Accept: 'application/pdf',
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/pdf',
    }
  };
}

/**
 * localStorage
 * 
 * @version 0.1 (2021.06.14 : usayama)
 * @version 0.2 (2021.06.15 : usayama) // json decode 追加
 */
export function getLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key))
}

/**
 * localStorage
 * 
 * @version 0.1 (2021.06.14 : usayama)
 * @version 0.2 (2021.06.15 : usayama) // json encode 追加
 */
export function setLocalStorage(key, items) {
    return localStorage.setItem(key, JSON.stringify(items))
}

/**
 * localStorage
 * 
 * @version 0.1 (2021.06.17 : usayama)
 */
export function removeLocalStorage(key) {
    localStorage.removeItem(key)
}

/**
 * 結果コード返却
 * 
 * @version 0.1 (2021.04.22 : usayama)
 * @version 0.2 (2021.05.26 : usayama) // status 受け取りに変更
 */
export function getResultStatusCode(obj)  {
  return (obj && obj.status && obj.status === 'success') ? 'success' : 'error'
}

/**
 * 結果コード返却
 * 
 * @version 0.1 (2021.04.22)
 * @update 
 */
/*
export function getResultStatusCode(str)  {
  return (str && str === 'success') ? 'success' : 'error';
}
*/

/**
 * meta タグの値取得
 * 
 * @version 1.0 (2020.04.02 : usayama)
 */
export function getMeta(metaName) {
  const metas = document.getElementsByTagName('meta');
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].getAttribute('content');
    }
  }
  return '';
}

/**
 * オブジェクト内の値検索
 * 
 * @version 1.0 (2020.05.17)
 */
export function filterSearch(value, key, array) {
  return array.find((e) => e[key] === value);
}

/**
 * 空オブジェクト判定
 * 
 * @version 1.0 (2020.01.12)
 */
export function isEmpty(obj) {
  for(var key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

/**
 * 空オブジェクト判定
 * 
 * @version 1.0 (2020.11.05)
 */
export function isObjectKeysEmpty(obj){
  return !Object.keys(obj).length;
}

/**
 * オブジェクトの全 key が空判定
 * 
 * @version 1.0 (2020.06.05)
 * @version 1.1 (2020.07.25) // obj の判定処理追加
 */
export function isObjectsEmpty(obj) {
  let result = true;
  if(obj) {
    const values = Object.values(obj);
    values.map((value) => {
      if(value !== "") {
        result = false;
      }
    });
  }
  return result;
}

/**
 * 変換 : 改行コード -> br
 * 
 * @version 1.0 (2020.06.12)
 * @version 1.1 (2020.11.05) // 空文字対応
 */
export function replaceBr(str) {
  return str ? str.replace(/\r?\n/g, '<br />') : '';
}

/**
 * 変換 : br -> 改行コード
 * 
 * @version 1.0 (2020.11.06)
 */
export function replaceNr(str) {
  return str ? str.replace(/<br \/>|<br>/g, '\r') : '';
}

/**
 * role color
 * 
 * @version 1.0 (2020.11.04 : usayama)
 */
export function getColor(num = null) {
    let colors = ['dark', 'danger', 'warning','success','primary','primary','warning','info','light','dark','danger'];
    return colors[num];
}

/**
 * role color
 * 
 * @version 1.0 (2020.11.04 : usayama)
 */
export function getRoleColor(num = null) {
    let colors = ['red', 'green','blue','primary','primary','warning','info','light','dark','danger'];
    return colors[num];
}

/**
 * role color
 * 
 * @version 1.0 (2020.06.19)
 */
export function getMemberTypeColor(num = null) {
    let colors = ['red', 'green','dark_yellow','primary','red','blue','info','light','dark','danger'];
    return colors[num];
}

/**
 * role color
 * 
 * @version 1.0 (2020.06.26)
 */
export function getUserPlanStatusColor(num = null) {
    let colors = ['', 'gray','','light_green','lightest_red','lightest_red','lightest_red','gray','gray','dark_gray'];
    return colors[num];
}

/**
 * status color
 * 
 * @version 1.0 (2020.11.04 : usayama)
 */
export function getStatusColor(num = null) {
    let colors = ['dark', 'danger','success','primary','darkest_red','dark_red','darkest_yellow','darkest_blue','dark','dark'];
    return colors[num];
}

/**
 * role color
 * 
 * @version 1.0 (2020.07.14)
 */
export function getPayWayColor(num = null) {
    let colors = ['dark', 'danger', 'primary','success','danger','primary','warning','dark','light','dark','danger'];
    return colors[num];
}

/**
 * status color
 * 
 * @version 1.0 (2020.07.01)
 */
export function getEmailTemplateColor(key = null) {
  // init
  let colors = [];
  colors['admin'] = 'dark';
  colors['mypage'] = 'danger';
  colors['register'] = 'success';
  colors['remove'] = 'primary';
  colors['update'] = 'darkest_red';
  colors['event'] = 'darkest_yellow';
  // return
  return colors[key];
}

/**
 * url から第1階層の path 取得
 * 
 * @version 1.1 (2021.01.14 : usayama)
 */
export function getFirstPath(path = '') {
  // init
  let pathname;
  // action
  if(path !== '') {
    pathname = path.split('/');
  } else {
    pathname = window.location.pathname.split('/');
  }
  // return
  return pathname[1];
}

/**
 * url から path 取得
 * 
 * @version 0.1 (2021.06.15 : usayama)
 */
export function getPaths(path = '') {
  // init
  let pathname;
  // action
  if(path !== '') {
    pathname = path.split('/');
  } else {
    pathname = window.location.pathname.split('/');
  }
  // return
  return pathname;
}

/**
 * 指定した文字数 抽出
 * 
 * @version 0.1 (2021.04.15)
 * @version 0.2 (2021.04.28 : usayama) // 指定文字未満のときの処理修正
 */
export function getSubstr(str, num = 5, addStr = "...") {
  return str.substr(0, num) + (str.length > (num + 1) ? addStr : '');
}

/**
 * 外部 script 読み込み
 * 
 * @version 0.1 (2021.06.16)
 */
export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
}